import React from 'react';
import { Header, List } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useListVals } from 'react-firebase-hooks/database';
import firebase from 'firebase/app';

export default function NotFound() {
  const [providers, loading, error] = useListVals(firebase.database().ref(`providers`), {
    keyField: 'id'
  });
  return (
    <div>
      <Header
        content="Onvindbaar"
        subheader="Sorry, we hebben overal gezocht maar niks kunnen vinden voor je."
      />
      {!loading &&
        !error && (
          <div style={{ marginTop: '2em' }}>
            <Header size="small">Misschien bedoelde je</Header>
            <List>
              {providers.map(provider => (
                <List.Item key={provider.id} as={Link} to={`providers/${provider.id}`}>
                  {provider.name}
                </List.Item>
              ))}
            </List>
          </div>
        )}
    </div>
  );
}
