import React from 'react';
import { render } from 'react-dom';
import App from './components/App';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import axios from 'axios';

// Extracts the subdomain from a hostname on planl.nl
const extractSubdomain = hostname => (/^(\w+)\.planl\.nl/.exec(hostname) || [])[1];

// Redirects admin.planl.nl to app.planl.nl/admin
const redirectIfNecessary = () => {
  const subdomain = extractSubdomain(window.location.hostname);
  if (subdomain && subdomain !== 'app') {
    window.location.replace(
      `${window.location.protocol}//app.planl.nl/${subdomain}${window.location.pathname}`
    );
  }
};
redirectIfNecessary();

// Initialize firebase and mount the app
const init = async firebaseConfig => {
  firebase.initializeApp(firebaseConfig);
  //firebase.auth().settings.appVerificationDisabledForTesting = true; // <= Comment out when testing with app verification
  firebase.auth().useDeviceLanguage();
  await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
  render(<App />, document.getElementById('root')); // eslint-disable-line
};

const DEV = process.env.NODE_ENV !== 'production';
if (DEV) {
  const config = {
    apiKey: 'AIzaSyBSUWxNk8IgkX_16rYaqukik7N6lt6_ALU',
    authDomain: 'planl-zorg-test.firebaseapp.com',
    databaseURL: 'https://planl-zorg-test.firebaseio.com',
    projectId: 'planl-zorg-test',
    storageBucket: 'planl-zorg-test.appspot.com',
    messagingSenderId: '710856813953'
  };
  init(config);
} else {
  // Obtain configuration automatically from server
  axios.get('/__/firebase/init.json').then(response => init(response.data));
}
