import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

// Renders a full page loading indicator
export default function FullPageLoadingIndicator({ children = 'Laden...', active = true }) {
  return (
    <Dimmer page active={active}>
      <Loader active>{children}</Loader>
    </Dimmer>
  );
}
