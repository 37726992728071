import firebase from 'firebase/app';
import 'firebase/auth';

export class basicPhoneVerification {
  constructor(buttonName) {
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(buttonName, {
      size: 'invisible'
    });
  }

  async requestVerification(phoneNumber) {
    const confirmationResult = await firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, this.recaptchaVerifier);
    this.confirmationResult = confirmationResult;
    return confirmationResult;
  }

  async verify(code) {
    if (!this.confirmationResult) {
      throw new Error('You must request a verifcation code before submitting it');
    }

    return this.confirmationResult.confirm(code);
  }

  cleanUp() {
    if (this.recaptchaVerifier) {
      this.recaptchaVerifier.clear();
    }
  }

  //moet nog getest worden
  async resendVerificationCode(phoneNumber) {
    await firebase.auth.PhoneAuthProvider.verifyPhoneNumber(phoneNumber, 60, true);
  }
}
