import React from 'react';
import { Switch, Redirect, useParams, useRouteMatch } from 'react-router-dom';
import firebase from 'firebase/app';
import FullPageLoadingIndicator from '../FullPageLoadingIndicator';
import ErrorMessage from '../ErrorMessage';
import { useObjectVal } from 'react-firebase-hooks/database';
import { Header } from 'semantic-ui-react';

export default function AppointmentModule() {
  const match = useRouteMatch();
  const { providerID } = useParams();
  const [provider, loading, error] = useObjectVal(
    firebase.database().ref(`providers/${providerID}`),
    { keyField: 'id' }
  );

  if (error) return <ErrorMessage />;
  if (loading) return <FullPageLoadingIndicator />;
  if (!provider) return <ErrorMessage />;

  window.location.href = provider.redirectURL;

  return (
    <>
      <Header>{provider.name}</Header>
      <Switch>
        <Redirect to={`${match.url}/selectTreatment`} />
      </Switch>
    </>
  );
}
