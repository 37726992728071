import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Header, Icon, Segment } from 'semantic-ui-react';
import ErrorMessage from './ErrorMessage';
import { useHistory, useLocation } from 'react-router-dom';
import { basicPhoneVerification } from '../api/basicPhoneVerification';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './Login.css';

const defaultError = {
  header: 'Onze oprechte excuses',
  content: 'Er ging iets mis. Controleer je verbinding en probeer het opnieuw.'
};

function parseError(error) {
  switch (error.code) {
    case 'auth/captcha-check-failed':
      return {
        header: 'Error: reCAPTCHA check gefaald.',
        content: 'De reCAPTCHA response code is ongeldig of verlopen.'
      };

    case 'auth/invalid-phone-number':
      return {
        header: 'Error: Ongeldig telefoonnumer',
        content: 'Je telefoonnummer heeft een ongeldig formaat.'
      };

    case 'auth/missing-phone-number':
      return {
        header: 'Error: Missende telefoonnummer.',
        content: 'Je hebt geen telefoonnummer meegegeven. Vul een telefoonnummer in a.u.b.'
      };

    case 'auth/invalid-verification-code':
      return {
        header: 'Error: Ongeldige verificatiecode.',
        content:
          'De verificatiecode die u heeft ingevuld is ongeldig. Vul a.u.b. een geldige verificatiecode in.'
      };

    case 'auth/missing-verification-code':
      return {
        header: 'Error: Verificatiecode ontbreekt.',
        content: 'De verificatiecode ontbreekt probeer a.u.b. opnieuw in te loggen.'
      };

    default:
      return defaultError;
  }
}

export default function Login() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isRequestingVerification, setIsRequestingVerification] = useState(false);
  const [isVerifyingVerificationCode, setIsVerifyingVerificationCode] = useState(false);
  const [shouldVerify, setShouldVerify] = useState(false);
  const [error, setError] = useState(null);
  const verifier = useRef(null);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const v = new basicPhoneVerification('sign-in-button');
    verifier.current = v;
    return () => v.cleanUp();
  }, []);

  function handleChangePhoneNumber(phoneNumber) {
    if (phoneNumber && phoneNumber.length > 1) {
      const editedNumber = phoneNumber.replace(`+00`, '+');
      setPhoneNumber(editedNumber);
    }
  }

  function handleSubmitPhoneNumber(event) {
    event.preventDefault();
    async function requestVerification() {
      try {
        setError(null);
        setIsRequestingVerification(true);
        if (!verifier.current) throw new Error('Verifier not found');
        await verifier.current.requestVerification(phoneNumber);
        setShouldVerify(true);
        setIsRequestingVerification(false);
      } catch (e) {
        console.error(e);
        setError(e);
        setIsRequestingVerification(false);
      }
    }
    requestVerification();
  }

  function handleSubmitVerificationCode(event) {
    event.preventDefault();
    async function verify() {
      try {
        setError(null);
        setIsVerifyingVerificationCode(true);
        if (!verifier.current) throw new Error('Verifier not found');
        await verifier.current.verify(verificationCode);
        setIsVerifyingVerificationCode(false);
        const nextURL = (location.state && location.state.from) || '/';
        history.replace(nextURL);
      } catch (e) {
        console.error(e);
        setError(e);
        setIsVerifyingVerificationCode(false);
      }
    }
    verify();
  }

  if (shouldVerify) {
    return (
      <div>
        {error && <ErrorMessage {...parseError(error)} />}

        <Header
          size="small"
          content="Voer de verificatiecode uit het SMS'je in"
          subheader={`Deze hebben we gestuurd naar ${phoneNumber}`}
        />

        <Form
          loading={isVerifyingVerificationCode || isRequestingVerification}
          onSubmit={handleSubmitVerificationCode}
        >
          <Form.Input
            label="Verificatiecode"
            value={verificationCode}
            onChange={(_, { value }) => setVerificationCode(value)}
            max="999999"
            name="verificationCode"
            autoFocus={!verificationCode}
          />
          <Segment basic clearing>
            <Button type="button" onClick={() => setShouldVerify(false)}>
              <Icon name="chevron left" />
              Terug
            </Button>
            <Button type="submit" floated="right">
              Verder
              <Icon name="chevron right" />
            </Button>
          </Segment>
        </Form>
      </div>
    );
  }

  return (
    <div>
      <Header
        size="small"
        content="Log in met je telefoonnummer"
        subheader={`Met een sms'je verifiëren we dat jij het bent.`}
      />
      <Form
        loading={isRequestingVerification || isVerifyingVerificationCode}
        onSubmit={handleSubmitPhoneNumber}
        error={!!error}
      >
        {error && <ErrorMessage {...parseError(error)} />}
        <PhoneInput
          placeholder={'Voer hier je nummer in'}
          value={phoneNumber}
          onChange={n => handleChangePhoneNumber(n)}
          autoFocus={!phoneNumber}
          country={'NL'}
        />

        <Segment basic clearing>
          <Button onClick={history.goBack} type="button">
            <Icon name="chevron left" />
            Terug
          </Button>
          <div style={{ display: 'inline-block', float: 'right' }}>
            <Button id="sign-in-button" type="submit" disabled={!isValidPhoneNumber(phoneNumber)}>
              Verder
              <Icon name="chevron right" />
            </Button>
          </div>
        </Segment>
      </Form>
    </div>
  );
}
