import React from 'react';
import { Segment, Menu, Header } from 'semantic-ui-react';

// Renders a navbar with our logo, a header + subheader, and a close button
export default () => (
  <Segment
    basic
    inverted
    color="teal"
    style={{ boxShadow: '0 1px 4px rgba(0,0,0,0.3)', padding: 0 }}
  >
    <Menu inverted pointing secondary style={{ border: 'none' }} icon="labeled">
      <Menu.Item header>
        <Header
          textAlign="left"
          inverted
          image={require('../assets/logo.png')}
          content="Planl"
          subheader="Plan je afspraak"
        />
      </Menu.Item>
    </Menu>
  </Segment>
);
