import React from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import NotFound from './NotFound';
import NavBar from './NavBar';
import AppointmentModule from './AppointmentModule';
import Login from './Login';

export default function App() {
  return (
    <Router>
      <NavBar />
      <Container textAlign="center">
        <div style={{ textAlign: 'left', maxWidth: 400, margin: '0 auto' }}>
          <Switch>
            <Route path="/providers/:providerID" component={AppointmentModule} />
            <Route path="/login" component={Login} />
            <Route component={NotFound} />
          </Switch>
        </div>
      </Container>
    </Router>
  );
}
