import React from 'react';
import { Message } from 'semantic-ui-react';

export default ({ header, content } = {}) => (
  <Message error>
    <Message.Header>{header || 'Sorry!'}</Message.Header>
    <p>
      {content || 'Er ging helaas iets mis. Controleer je verbinding en probeer het later opnieuw.'}
    </p>
  </Message>
);
